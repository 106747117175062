import { forwardRef } from "react";
import joinClassNames from "classnames";

import Loader24Icon from "icons/loader24.svg?react";

import { Themes, Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  isBlock?: boolean;
  themeName?: Themes;
  size?: Sizes;
  isLoading?: boolean;
  isSelected?: boolean;
  isIconOnly?: boolean;
  classNames?: {
    loader?: string;
  };
}

const Button: AS.ForwardRef<Props, "button", HTMLButtonElement> = forwardRef<
  HTMLButtonElement,
  AS.ForwardRefProps<Props, "button">
>(
  (
    {
      children,
      isBlock,
      themeName = "default",
      size = "lg",
      isLoading,
      isSelected,
      disabled,
      isIconOnly,
      className,
      classNames = {},
      as: Component = "button",
      ...props
    },
    ref,
  ) => {
    const isDisabled = isLoading || disabled;

    return (
      <Component
        aria-selected={isSelected}
        aria-disabled={isDisabled}
        data-loading={isLoading}
        data-block={isBlock}
        data-theme={themeName}
        data-size={size}
        data-icon-only={isIconOnly}
        className={joinClassNames(
          classes.themePresets,
          classes.sizePreset,
          classes.blank,
          classes.button,
          className,
        )}
        disabled={isDisabled}
        type={Component === "button" ? "button" : undefined}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <Loader24Icon
            className={joinClassNames(classes.loader, classNames.loader)}
          />
        ) : (
          children
        )}
      </Component>
    );
  },
);

export default Button;
